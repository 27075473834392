import React, { useEffect } from 'react';
import './App.css';

function App() {

    const [time, setTime] = React.useState(10);

    setTimeout(() => {
        window.location.href = 'https://deliverycopilot.avanade.com/mematcamigration';
    }, 10000);

    // create a time to decrase the time by 1s every second
    useEffect(() => {
        const timer = setInterval(() => {
            setTime((prevTime) => prevTime - 1);
        }, 1000);

        // Clear all local storage
        localStorage.clear();

        // Remove all cookies
        const cookies = document.cookie.split("; ");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }

        return () => {
            clearInterval(timer);
        }
    }, []);
    

    

  return (
    <div className="App">
        <img src="logo.png" alt="Avanade" />
        <h1>We've moved!</h1>
        <p>The Manage + Evolve Maturity Assessment (MEMA) and the Transition Complexity Assessment (TCA) have moved to a new home on the Avanade Delivery Copilot</p>
        <p>You'll be taken there in {time}s</p>
        <p><a href="https://deliverycopilot.avanade.com/mematcamigration">click here to go there now</a>.</p>
    </div>
  );
}

export default App;
